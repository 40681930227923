$(document).ready(function () {
    initHeaderScroll();
    initSlicknav();
    initHomepageHeader();
    initBuffer();
    initProefles();
    initCarousel();
    initEqualHeights();
    initHomeForms();
    initFindLocation();
    postcodeApi();
    initPakketten();
    initOrderOrder();
    initRijschoolDetail();
    initPakketBestellen();
    initUrlScroll();
    initTimeperiods();
    initScrollTimeperiods();
    initVacatureFileInput();
    initCatalogNav();
    initDatePickers();
    inithandleHeader();
    initHoeSlider();
    initFaqTheorie();
    initHandleHomeShape();
    initUspSliders();
    initInfoSlider();
    initReviewSlider();
    initRedenenSlider();
    initStars();

    handleForm();
    fixLocalMedia();

    if ($('#rijscholen').length) {
        initRijscholen();
    }

    if ($('#awards').length > 0) {
        if ($(window).width() <= 768) {
            i1 = $('li:first-of-type', '#awards');
            i2 = $('li:nth-of-type(2)', '#awards');
            i3 = $('li:nth-of-type(3)', '#awards');
            i4 = $('li:nth-of-type(4)', '#awards');
            i5 = $('li:nth-of-type(5)', '#awards');

            let myarr = [i1, i2, i3, i4, i5];
            let active = 1;

            setInterval(() => {
                $(myarr[active - 1]).fadeOut(500);
                if (active === 4) {
                    active = 0
                }
                setTimeout(() => {
                    $(myarr[active]).fadeIn(500);
                    active = active + 1;
                }, 500)
            }, 5000)
        }
    }

});

$(window).on('load', function () {
    if ($('#cookiebox-nieuw').length) {
        initCookiebox();
    }
});

$(document).on('scroll', () => {
    initHeaderScroll();
});

function initHeaderScroll() {
    window.addEventListener('scroll', (ev) => {
        if (window.scrollY > 0) {
            document.querySelector('header').classList.add('scrolled');
        } else {
            document.querySelector('header').classList.remove('scrolled');
        }
    });
}


function initEqualHeights() {
    if ($('.equalHeights').length) {
        $('.equalHeights').equalHeights();
    }
}

function inithandleHeader() {
    if (window.innerWidth > 1200) {
        const app = document.querySelector('#app');
        const bottomBar = document.querySelector('header .bottom-bar');
        window.addEventListener('scroll', (ev) => {
            if (window.scrollY > 40) {
                app.style.paddingTop = (parseInt(bottomBar.offsetHeight) + 40) + 'px';
                document.querySelector('header').classList.add('sticky');
            } else {
                app.style.paddingTop = 0 + 'px';
                document.querySelector('header').classList.remove('sticky');
            }
        });
    }
}

function initHandleHomeShape() {
    if (document.querySelector('#homepage')) {
        if (window.innerWidth > 992) {
            const initialShapeOne = document.querySelector('#masthead .shape');
            const initialShapeOneDistance = initialShapeOne.getBoundingClientRect().left;
            let initialShapeTwoWidth = 0;
            let issetInitialShapeTwoWidth = false;

            const updateShapeTwoWidth = () => {
                const shapeOne = document.querySelector('#masthead .shape');
                const shapeTwo = document.querySelector('.home-intro .shape');
                const shapeOneDistance = shapeOne.getBoundingClientRect().left;
                const shapeWidth = (parseInt(shapeOne.offsetWidth) * 0.65) + shapeOneDistance;
                shapeTwo.style.width = shapeWidth + 'px';

                if(!issetInitialShapeTwoWidth) {
                    initialShapeTwoWidth = shapeWidth;
                    issetInitialShapeTwoWidth = true;
                }else{
                    const percentage = (shapeWidth / initialShapeTwoWidth) * 100;
                    shapeTwo.style.clipPath = `polygon(100% 0, 0 0, 0 ${percentage}%)`;
                }
            };

            updateShapeTwoWidth();
            const initialShapeTwo = document.querySelector('#masthead .shape');
            window.addEventListener('resize', updateShapeTwoWidth);
        }
    }
}

function initSlicknav() {
    $('header nav').slicknav({
        label: '',
        prependTo: 'header .container'
    });

    $('.slicknav_btn').click(function () {
        if (!$('.slicknav_nav').hasClass('slicknav_hidden')) {
            $('body').css({
                overflow: 'hidden'
            });
        } else {
            $('body').css({
                overflow: 'initial'
            });
        }
    });
}

function initBuffer() {
    $('.buffer').css({
        height: $('header').outerHeight()
    });
}

function initCarousel() {
    if ($('#carousel .slider').length) {
        $('#carousel .slider').slick({
            autoplay: true,
            infinite: true,
            autoplaySpeed: 7000,
            arrows: false,
            pauseOnHover: false,
            pauseOnFocus: false
        });

        if ($('#carousel #more')) {
            $('#carousel #more').click(function () {
                $('html, body').animate({
                    scrollTop: $('#campagne').offset().top - $('header').outerHeight()
                }, 1000);
            });
        }
    }
}

function initUspSliders() {
    if ($('#homepage #uspSliderOne').length) {
        $('#uspSliderOne .slider').slick({
            infinite: true,
            speed: 9000,
            autoplay: true,
            autoplaySpeed: 0,
            arrows: false,
            cssEase: 'linear',
            slidesToShow: 3,
            pauseOnHover: false,
            pauseOnFocus: false,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        });

        $('#uspSliderTwo .slider').slick({
            infinite: true,
            speed: 9000,
            autoplay: true,
            autoplaySpeed: 0,
            arrows: false,
            cssEase: 'linear',
            slidesToShow: 3,
            pauseOnHover: false,
            pauseOnFocus: false,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        });
    }
}

function initInfoSlider() {
    if ($('#infoSlider').length) {
        $('#infoSlider .slider').slick({
            autoplay: true,
            infinite: false,
            autoplaySpeed: 7000,
            arrows: false,
            slidesToShow: 3,
            pauseOnHover: false,
            pauseOnFocus: false,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        });
        $(".info .slick-prev").click(function () {
            $("#infoSlider .slider").slick("slickPrev");
        });

        $(".info .slick-next").click(function () {
            $("#infoSlider .slider").slick("slickNext");
        });
    }
}

function initFaqTheorie() {
    if (document.querySelector('#faqTheorie')) {
        const faqTheorieItems = document.querySelectorAll('#faqTheorie .question');

        faqTheorieItems.forEach(item => {
            item.addEventListener('click', () => {
                const isActive = item.classList.contains('active');

                faqTheorieItems.forEach(i => {
                    i.classList.remove('active');
                    i.querySelector('.status i').classList.remove('fa-minus');
                    i.querySelector('.status i').classList.add('fa-plus');
                });

                if (!isActive) {
                    item.classList.add('active');
                    item.querySelector('.status i').classList.remove('fa-plus');
                    item.querySelector('.status i').classList.add('fa-minus');
                }
            });
        });
    }
}

function initHoeSlider() {
    if ($('#hoeSlider .slider').length) {
        const slider = $('#hoeSlider .slider').slick({
            autoplay: false,
            infinite: false,
            autoplaySpeed: 5000,
            arrows: false,
            pauseOnHover: false,
            pauseOnFocus: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        });

        new IntersectionObserver((entries, observer) => {
            if (entries[0].isIntersecting) {
                observer.disconnect();
                slider.slick("slickSetOption", "autoplay", true, true);
            }
        }).observe(document.querySelector('#hoeSlider .slider'));

        $(".hoe .slick-prev").click(function () {
            $("#hoeSlider .slider").slick("slickPrev");
        });

        $(".hoe .slick-next").click(function () {
            $("#hoeSlider .slider").slick("slickNext");
        });
    }
}

function initReviewSlider() {
    if ($('#reviewSlider .slider').length) {
        $('#reviewSlider .slider').slick({
            autoplay: true,
            infinite: true,
            autoplaySpeed: 5000,
            arrows: false,
            pauseOnHover: false,
            pauseOnFocus: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        });

        $(".hoe .slick-prev").click(function () {
            $("#reviewSlider .slider").slick("slickPrev");
        });

        $(".hoe .slick-next").click(function () {
            $("#reviewSlider .slider").slick("slickNext");
        });
    }
}

function initHomepageHeader() {
    if ($('#homepage #masthead .slider').length) {
        $('#homepage #masthead .slider').slick({
            autoplay: true,
            autoplaySpeed: 4000,
            arrows: false,
            fade: true,
            speed: 1000,
            pauseOnHover: false,
            pauseOnFocus: false
        });
    }
}

function initProefles() {
    if ($(window).scrollTop() > 100) {
        $('.proefles').css({
            bottom: '15px'
        });
    } else {
        $('.proefles').css({
            bottom: '-100px'
        });
    }

    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('.proefles').css({
                bottom: '15px'
            });
        } else {
            $('.proefles').css({
                bottom: '-100px'
            });
        }
    });
}

function initHomeForms() {
    if ($('section#forms').length) {
        $('section#forms .choices .choice').each(function () {
            $(this).click(function () {
                $('section#forms .choices .choice.active').removeClass('active');
                $('section#forms .forms .form.active').removeClass('active');

                $(this).addClass('active');
                $($(this).data('form')).addClass('active');

                if ($(this).data('form') === '#bel-form') {
                    $('.forms').removeClass('school').addClass('bel');
                } else if ($(this).data('form') === '#school-form') {
                    $('.forms').removeClass('bel').addClass('school');
                }
            });
        });
    }
}

function initFindLocation() {
    if ($('.location').length) {
        $('.location').click(function () {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(showLocation);
            }
        });
    } else if ($('.location-page').length) {
        $('.location-page').click(function () {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(showLocationPage);
            }
        });
    }
}

function showLocation(position) {
    var latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
    geocoder = new google.maps.Geocoder();

    geocoder.geocode({'latLng': latlng}, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
            if (results[0]) {
                for (j = 0; j < results[0].address_components.length; j++) {
                    if (results[0].address_components[j].types[0] == 'postal_code') {
                        $('#search').val(results[0].address_components[j].short_name);

                        $('#frmRijschool').submit();
                    }
                }
            }
        } else {
            console.log("Geocoder failed due to: " + status);
        }
    });
}

function showLocationPage(position) {
    var latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
    geocoder = new google.maps.Geocoder();

    geocoder.geocode({'latLng': latlng}, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
            if (results[0]) {
                for (j = 0; j < results[0].address_components.length; j++) {
                    if (results[0].address_components[j].types[0] == 'postal_code') {
                        $('#search').val(results[0].address_components[j].short_name);

                        $('#frmRijschool').submit();
                    }
                }
            }
        } else {
            console.log("Geocoder failed due to: " + status);
        }
    });
}

function postcodeApi() {
    $('#housenumber, #housenumber_addition').focusout(function () {
        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            type: 'POST',
            data: {
                zipcode: $('#zipcode').val().replace(' ', ''),
                housenumber: $('#housenumber').val().replace(' ', ''),
                housenumber_addition: $('#housenumber_addition').val().replace(' ', '')
            },
            url: '/request/adresbyzipcode',
            async: true,
            success: function (result) {
                if (result) {
                    result = JSON.parse(result);
                    if (typeof (result.exception) == 'undefined') {
                        $('#street').val(result.street);
                        $('#city').val(result.city);
                    }
                }
            }
        });
    });
}

// Rijscholen
function initRijscholen() {
    $.ajax({
        method: 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        beforeSend: function () {
            $('#rijscholen .loader').fadeIn('fast');
        },
        url: '/request/locations',
        data: {
            query: $('#search').val()
        }
    })
        .done(function (response) {
            initRijschoolCarousel(response);
            initMaps(response);

            setTimeout(function () {
                $('#rijscholen .loader').fadeOut('fast');
            }, 500);
        });

    initRijscholenSearch();
}

function initRijschoolCarousel(response) {
    if ($('#rijscholen .rijscholen').length > 0) {

        if (response.length === 0) {
            response = false;
        }

        var rijschool = '',
            json = '';

        json = JSON.parse(response);

        if (!$('#rijscholen .rijscholen').hasClass('slick-initialized')) {
            $('#rijscholen .rijscholen').slick();
        }
        $('#rijscholen .rijscholen').slick('unslick');
        $('#rijscholen .rijscholen').html('');


        for (var i = 0; i < json.length; i++) {
            rijschool = '<div class="rijschool" data-item="' + i + '">';

            if (json[i].distance || json[i].distance === 0) {
                rijschool += '<h3 class="title">' + json[i].name + ' <small class="pull-right">' + Math.ceil(json[i].distance) + ' km</small></h3>';
            } else {
                rijschool += '<h3 class="title">' + json[i].name + '</h3>';
            }
            rijschool += '<p>\n\
							' + json[i].street + ' ' + json[i].housenumber + json[i].housenumber_additions + ' <br>\n\
							' + json[i].zipcode + ' ' + json[i].city + ' <br>\n\
							T. ' + json[i].phone + ' <br>\n\
						</p>\n\
						<a href="/rijscholen/' + slugify(json[i].name) + '-' + json[i].id + '" class="btn btn-orange">Meer informatie</a>\n\
						<form action="/gratis-proefles" method="POST">\n\
							<input type="hidden" name="_token" value="' + $('meta[name="csrf-token"]').attr('content') + '">\n\
							<input type="hidden" name="id" value="' + json[i].id + '">\n\
							<button class="btn btn-blue">Gratis proefles</button>\n\
						</form>\n\
					</div>';

            $('#rijscholen .rijscholen').append(rijschool);
        }

        $('#rijscholen .rijscholen').on('init', function () {
            $('#maps').css({
                height: $('#rijscholen .holder').outerHeight()
            });
        });
        $('#rijscholen .rijscholen').slick({
            prevArrow: '<button class="prev"><i class="icon icon-arrows-left"></i></button>',
            nextArrow: '<button class="next"><i class="icon icon-arrows-right"></i></button>',
            vertical: true,
            verticalSwiping: true,
            infinite: false,
            adaptiveHeight: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            swipe: false,

            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]

        });
    }
}

function initMaps(json) {
    json = JSON.parse(json);

    var locations = [],
        markers = [];

    if (json.length > 0) {
        for (var i = 0; i < json.length; i++) {
            locations.push(
                {
                    id: json[i].id,
                    lat: parseFloat(json[i].latitude),
                    lng: parseFloat(json[i].longitude),
                    name: json[i].name,
                    address: json[i].street + ' ' + json[i].housenumber + json[i].housenumber_additions,
                    zipcode: json[i].zipcode,
                    city: json[i].city,
                    phone: json[i].phone
                }
            );
        }
        var bounds = new google.maps.LatLngBounds();

        var map = new google.maps.Map(document.getElementById('maps'), {});

        for (i = 0; i < locations.length; i++) {
            var icon = new google.maps.MarkerImage(
                '/img/map/location-map.png',
                null, /* size is determined at runtime */
                null, /* origin is 0,0 */
                null, /* anchor is bottom center of the scaled image */
                new google.maps.Size(30, 45)
            );

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(locations[i].lat, locations[i].lng),
                map: map,
                icon: icon
            });

            bounds.extend(marker.position);

            google.maps.event.addListener(marker, 'click', (function (marker, i) {
                return function () {
                    infoBubble = new InfoBubble({
                        map: map,
                        content: '<div class="infobubble">\n\
									<h3 class="title">' + locations[i].name + '</h3>\n\
									<p style="margin-bottom: 0; padding-bottom: 0;">\n\
										<strong>' + locations[i].address + '</strong><br>\n\
									   ' + locations[i].zipcode + ' ' + locations[i].city + '<br>\n\
									   T. ' + locations[i].phone + '<br>\n\
									</p>\n\
									<a href="/rijscholen/' + slugify(locations[i].name) + '-' + locations[i].id + '" class="btn btn-orange">Meer informatie</a>\n\
									<form action="/gratis-proefles" method="POST">\n\
										<input type="hidden" name="_token" value="' + $('meta[name="csrf-token"]').attr('content') + '">\n\
										<input type="hidden" name="id" value="' + locations[i].id + '">\n\
										<button class="btn btn-blue">Gratis proefles</button>\n\
									</form>\n\
								</div>',
                        shadowStyle: 0,
                        padding: 0,
                        borderRadius: 5,
                        arrowSize: 15,
                        borderWidth: 0,
                        minWidth: 320,
                        maxWidth: 320,
                        closeSrc: '../img/infobubble_close.svg',
                        backgroundColor: '#00B6FE',
                        borderWidth: '3px',
                        borderColor: '#ffffff'
                    });

                    $('#rijscholen .rijscholen').slick('slickGoTo', i);

                    infoBubble.open(map, marker);
                }
            })(marker, i));

            markers.push(marker);
        }

        $('#rijscholen .rijscholen .rijschool').each(function () {

            $(this).click(function () {
                infoBubble = new InfoBubble({
                    map: map,
                    content: '<div class="infobubble">\n\
								<h3 class="title">' + locations[$(this).attr('data-item')].name + '</h3>\n\
								<p style="margin-bottom: 0; padding-bottom: 0;">\n\
									<strong>' + locations[$(this).attr('data-item')].address + '</strong><br>\n\
								   ' + locations[$(this).attr('data-item')].zipcode + ' ' + locations[$(this).attr('data-item')].city + '<br>\n\
								   T. ' + locations[$(this).attr('data-item')].phone + '<br>\n\
								</p>\n\
								<a href="/rijscholen/' + slugify(locations[$(this).attr('data-item')].name) + '-' + locations[$(this).attr('data-item')].id + '" class="btn btn-orange">Meer informatie</a>\n\
								<form action="/gratis-proefles" method="POST">\n\
									<input type="hidden" name="_token" value="' + $('meta[name="csrf-token"]').attr('content') + '">\n\
									<input type="hidden" name="id" value="' + locations[$(this).attr('data-item')].id + '">\n\
									<button class="btn btn-blue">Gratis proefles</button>\n\
								</form>\n\
							</div>',
                    shadowStyle: 0,
                    padding: 0,
                    borderRadius: 5,
                    arrowSize: 15,
                    borderWidth: 0,
                    minWidth: 380,
                    maxWidth: 380,
                    closeSrc: '../img/infobubble_close.svg',
                    backgroundColor: '#00B6FE',
                    borderWidth: '3px',
                    borderColor: '#ffffff'
                });

                var listener = google.maps.event.addListener(map, "idle", function () {
                    map.setZoom(14);
                });
                infoBubble.open(map, markers[$(this).attr('data-item')]);
            });

        });

        map.fitBounds(bounds);

        // var listener = google.maps.event.addListener(map, "idle", function () {
        // 	map.setZoom(7);
        // 	google.maps.event.removeListener(listener);
        // });

        var markerCluster = new MarkerClusterer(map, markers, {
            styles: [
                {
                    url: '/img/map/outlet_marker_35.png',
                    height: 35,
                    width: 35,
                    textColor: 'white',
                    opt_anchor: [16, 0],
                    opt_textColor: '#FFFFFF'
                },
                {
                    url: '/img/map/outlet_marker_45.png',
                    height: 45,
                    width: 45,
                    textColor: 'white',
                    opt_anchor: [24, 0],
                    opt_textColor: '#FFFFFF'
                },
                {
                    url: '/img/map/outlet_marker_55.png',
                    height: 55,
                    width: 55,
                    textColor: 'white',
                    opt_anchor: [32, 0],
                    opt_textColor: '#FFFFFF'
                },
                {
                    url: 'https://googlemaps.github.io/js-marker-clusterer/images/pin.png',
                    height: 48,
                    width: 30,
                    anchor: [-18, 0],
                    textColor: '#ffffff',
                    textSize: 10,
                    iconAnchor: [15, 48]
                }
            ]
        });
    } else {
        $('#maps').html('<h3>Er zijn geen rijscholen in de buurt van <strong>' + $('#frmRijschool #search').val() + '</strong> gevonden...</h3><p>Zoekt u toch een rijschool, probeer dan op de kaart te zoeken.</p>');
    }
}

function initRijscholenSearch() {
    $('#frmRijschool').submit(function (e) {
        e.preventDefault();

        $('#search').blur();
        $('button').blur();

        $.ajax({
            method: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            beforeSend: function () {
                $('#rijscholen .loader').fadeIn('fast');
            },
            url: '/request/locations',
            data: {
                query: $('#search').val()
            }
        })
            .done(function (response) {
                initRijschoolCarousel(response);
                initMaps(response);

                setTimeout(function () {
                    $('#rijscholen .loader').fadeOut('fast');
                }, 500);
            });
    });
}

function slugify(text) {
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
}

// Pakketten
function initPakketten() {
    if ($('.mobile-nav').length) {
        $('.mobile-nav nav ul li').each(function () {
            $(this).find('a').click(function (e) {
                var el = $(this);
                e.preventDefault();

                $('.mobile-nav nav ul li a.active').removeClass('active');
                el.addClass('active');

                $('html, body').animate({
                    scrollTop: $($(this).attr('href')).offset().top - ($('.mobile-nav').outerHeight() + 15)
                });
            });
        });

        $(window).scroll(function () {
            $('.products .product-column, .products .product').each(function () {
                if (($(window).scrollTop() + ($('.mobile-nav').outerHeight() + 15)) >= $(this).offset().top && ($(window).scrollTop() + ($('.mobile-nav').outerHeight() + 15)) <= ($(this).offset().top + $(this).outerHeight())) {
                    $('.mobile-nav nav ul li a.active').removeClass('active');
                    $('.mobile-nav nav ul li a[href="#' + $(this).attr('id') + '"]').addClass('active');
                }
            });
        });
    }
}


// Cookiebox
function initCookiebox() {
    var cookielevels = ['1', '2', '3'];

    if (cookielevels.indexOf(getCookie('cookielevel')) === -1) {
        $.fancybox('#cookiebox-nieuw', {
            closeBtn: false,
            helpers: {
                overlay: {
                    closeClick: false
                }
            }
        });

        $('#cookiebox-nieuw .btn-cookie').click(function () {
            setCookie('cookielevel', 3, 365);

            $.fancybox.close();

            location.reload();
        });

        $('#cookiebox-nieuw .btn-cookie_not-accept').click(function () {
            $.fancybox.close();

            setTimeout(function () {
                $.fancybox('#cookiebox-settings', {
                    closeBtn: false,
                    helpers: {
                        overlay: {
                            closeClick: false
                        }
                    }
                });
            }, 500);
        });

        $('#cookieform').submit(function (e) {
            e.preventDefault();

            setCookie('cookielevel', $('input[name="cookiesetting"]:checked').val(), 365);

            $.fancybox.close();

            location.reload();
        });
    }
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}


function initOrderOrder() {
    if ($('#page .js-cart--action, #homepage .js-cart--action, #campagne .js-cart--action').length) {
        $('#page .js-cart--action, #homepage .js-cart--action, #campagne .js-cart--action').each(function () {
            var clicked = 0;

            $(this).click(function (e) {
                e.preventDefault();

                if (clicked === 0) {
                    $(this).html($(this).html() + ' <i class="fa fa-spinner fa-spin"></i>');

                    if ($(this).data('category') == 'theorie') {
                        var variant_id = 7;
                    } else if ($(this).data('category') == 'actie') {
                        var variant_id = 12;
                    } else if ($(this).data('category') == 'top') {
                        var variant_id = 13;
                    } else if ($(this).data('category') == 'spoed') {
                        var variant_id = 15;
                    } else if ($(this).data('category') == 'master') {
                        var variant_id = 14;
                    } else if ($(this).data('category') == 'slipcursus') {
                        var variant_id = 8;
                    } else if ($(this).data('category') == 'opfriscursus') {
                        var variant_id = 9;
                    } else if ($(this).data('category') == 'theory_en') {
                        var variant_id = 10;
                    }

                    var quantity = 1;
                    var nexturl = '/shop/order/checkout';

                    $.ajaxSetup({
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        }
                    });

                    $.ajax({
                        url: '/shop/cart/delete/categorie',
                        type: 'GET',
                        data: {
                            'categorie': 3
                        },
                        success: function () {
                            $.ajax({
                                url: '/shop/cart/delete/categorie',
                                type: 'GET',
                                data: {
                                    'categorie': 1
                                },
                                success: function () {
                                    // Wel / niet toevoegen
                                    $.ajax({
                                        url: '/shop/cart/add',
                                        type: 'POST',
                                        data: {
                                            quantity: quantity,
                                            product_variant_id: variant_id
                                        },
                                        success: function () {
                                            window.location = nexturl;
                                        }
                                    });
                                }
                            });
                        }
                    });

                    clicked = 1;
                }
            });
        });
    }
}

function initRijschoolDetail() {
    if ($('.banner-proefles img').length) {
        $('.banner-proefles img').click(function () {
            $('.banner-proefles form').submit();
        });
    }
}

function initPakketBestellen() {
    if ($('#checkout').length) {
        if ($('#checkout .radios input[name="proefles"]:checked').val() === 'Nee') {
            $('#checkout .checkbox').fadeIn('fast');
        } else {
            $('#checkout .checkbox').fadeOut('fast');
        }

        $('#checkout .radios input[name="proefles"]').change(function () {
            if ($(this).val() === 'Nee') {
                $('#checkout .checkbox').fadeIn('fast');
            } else {
                $('#checkout .checkbox').fadeOut('fast');
            }
        });
    }
}

function initUrlScroll() {
    var href = window.location.href;
    var reg = new RegExp('[?&]scroll=([^&#]*)', 'i');
    var string = reg.exec(href);


    if (string) {
        $('html, body').animate({
            scrollTop: $('#' + string[1]).offset().top - $('header').outerHeight()
        }, 1000);
    }
}

function initRecaptcha() {
    document.getElementById($('.g-recaptcha').attr('data-form')).submit();
}

function initTimeperiods() {
    if ($('.slots').length) {
        $('.slots .slot').each(function () {
            $(this).click(function () {
                var selected = $(this);


                if (!selected.hasClass('selected')) {
                    $('.slots .form button').removeAttr('disabled');
                    $('.slots .slot.selected').removeClass('selected');
                    selected.removeClass('not-selected');
                    selected.addClass('selected');

                    $('input[name="start"]').val(selected.data('start'));
                    $('input[name="end"]').val(selected.data('end'));

                    $('.slots .slot').each(function () {
                        if ($(this).data('slot') !== selected.data('slot')) {
                            $(this).addClass('not-selected');
                        }
                    });
                }

            });
        });
    }
}

function initScrollTimeperiods() {
    if ($('.slots .form').length) {
        var currenttop = $('.slots .form').offset().top,
            currentleft = $('.slots .form').offset().left,
            currentwidth = $('.slots .form').outerWidth();

        $(window).scroll(function () {
            if ($(window).scrollTop() + $('header').outerHeight() >= currenttop) {
                $('.slots .form').css({
                    position: 'fixed',
                    top: $('header').outerHeight(),
                    left: currentleft,
                    width: currentwidth
                });
            } else {
                $('.slots .form').css({
                    position: 'static'
                });
            }
        });
    }
}

function initRijschoolDetail() {
    if ($('.banner-proefles img').length) {
        $('.banner-proefles img').click(function () {
            $('.banner-proefles form').submit();
        });
    }

    $('.geslaagd').each(function () {
        if ($(this).find('.col-md-8').outerHeight() > 300) {
            $(this).addClass('closed');
        }
    });

    $('.geslaagd.closed .more').click(function () {

        if ($(this).parent().hasClass('active')) {
            $(this).text('Lees meer');

            $(this).parent().css({
                height: '300'
            });
        } else {
            $(this).text('Laat minder zien');

            $(this).parent().css({
                height: $(this).parent().find('.col-md-8').outerHeight() + 60
            });
        }

        $(this).parent().toggleClass('active');
    });
}

function initCatalogNav() {
    if ($('.mobile-nav').length > 0) {
        $(document).scroll(function () {
            if ($(this).scrollTop() > 20) {
                $('.mobile-nav').css({
                    zIndex: 3
                });
            } else {
                $('.mobile-nav').css({
                    zIndex: 1
                });
            }
        });
    }
}

function initVacatureFileInput() {
    $("#cv").on("change", function () {
        // Name of file and placeholder
        $('#files').empty();
        var fileLength = this.files.length
        var count = 0;
        if (fileLength > 2) {
            alert("U kunt alleen maximaal 2 bestanden selecteren");
            $(this).val('')
            return;
        }
        Array.from(this.files).forEach(file => {
            count++

            if (count == 1 && count < fileLength) {

                $('#files').append(file.name + ' en ');
            } else if (count == 1 && count == length) {
                $('#files').append(file.name);
            } else {
                $('#files').append(file.name);
            }
            $(this).next().css('color', 'black')
        });

    });
    $('#vacature-submit').on('click', function () {
        if ($('#cv').val() == "") {
            $('#cv').next().css('color', 'red')
        }
    })
}

function initDatePickers() {
    const pickers = document.querySelectorAll('.datepicker');

    if (pickers) {
        let date = new Date();
        date.setFullYear(date.getFullYear() - 16);
        date.setMonth(date.getMonth() - 6);

        pickers.forEach(picker => {
            picker.addEventListener('keyup', () => {
                picker.value = '';
            });

            new Datepicker(picker, {
                language: 'nl',
                maxDate: date,
                format: 'dd-mm-yyyy'
            });
        });
    }
}

function handleForm() {
    const formGroups = document.querySelectorAll('.form-group:not(.select)');

    formGroups.forEach((item) => {
        const input = item.querySelector('input, textarea');
        const handleFocus = () => {
            item.classList.add('focus');
        }
        const handleBlur = () => {
            if (input.value.length === 0) {
                item.classList.remove('focus');
            }
        }
        const handleInput = () => {
            if (input.value.length === 0 && document.activeElement === input) {
                item.classList.add('focus');
            } else if (input.value.length > 0) {
                item.classList.add('focus');
            } else {
                item.classList.remove('focus');
            }
        }

        input.addEventListener('focus', handleFocus);
        input.addEventListener('blur', handleBlur);
        input.addEventListener('input', handleInput);

        if (input.value.length > 0) {
            item.classList.add('focus');
        }
    });
}

function fixLocalMedia() {
    const images = document.querySelectorAll('img');

    images.forEach(image => {
        if (image.src.includes('/media/') && !image.src.includes('https://www.')) {
            image.src = image.src.replace('http://', 'https://www.').replace('.test', '');
        }
    });
}

function initRedenenSlider() {
    if ($('#redenen-new').length) {
        $('#redenen-slider .slider').slick({
            autoplay: true,
            infinite: true,
            autoplaySpeed: 7000,
            centerMode: true,
            arrows: false,
            slidesToShow: 1,
            pauseOnHover: false,
            pauseOnFocus: false,
            dots: true,
            customPaging: function() {
                return '<div class="dot"></div>';
            }
        });
    }
}


function initStars() {

    if($('.stars').length) {

            const starsContainers = document.querySelectorAll('.stars');

            starsContainers.forEach(starsContainer => {
                this.setStars(starsContainer);
            })

    }
}



function setStars(starsContainer) {
    const goldStars = starsContainer.querySelector('.gold');
    if (goldStars) {
        const rating = parseFloat(starsContainer.getAttribute('data-rating'));
        const percentage = rating * 10; // Ensure data-rating is out of 10
        goldStars.style.clipPath = `polygon(0 0, ${percentage}% 0, ${percentage}% 100%, 0% 100%)`;
    }
}


