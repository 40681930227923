$.fn.equalHeights = function () {
    var $this = $(this);

    $this.each(function () {
        var $row = $(this);
        
        var childs = $row.data('equals');
        var children = childs.split(",");

        $.each (children, function(index, value) {
            var current_tallest = 0;
            var elements = $row.find(String(value));

            if ($this.data('row')) {
                var per_row = $this.data('row');
            } else {
                var per_row = elements.length;
            }
            
            for (var i = 0; i < elements.length; i+=per_row) {
                elements.slice(i, i+per_row).each(function() {
                    $(this).css('min-height', '0px');
                    if ($(this).outerHeight() > current_tallest) { 
                        current_tallest = $(this).outerHeight(); 
                    }  
                });

                if($(window).width() > 767 || $row.data('force') == true){
                    elements.slice(i, i+per_row).css({'min-height': current_tallest +'px'});
                }
                current_tallest = 0;
            }
        });
    });
    
    return this;
};