 $(document).ready(function(){

    if( $('.cart').length > 0 ) initDiscountcoupon();

});

//
// INIT DISCOUNTCOUPON
//
var initDiscountcoupon = function(){

    $('.c-discountcoupon__trigger').click(function(){
        $(this).hide(); 
        $('.c-discountcoupon__form').show();
    });

    $('.c-discountcoupon__button__add').click(function(){
        addDiscountcoupon($(this));
    });

    $('.c-discountcoupon__button__remove').click(function(){
        removeDiscountcoupon();
    });

}

//
// ADD COUPON TO CART
//
var addDiscountcoupon = function(el){
    
    console.log(el);

    var code = el.parent().find('.c-discountcoupon__input').val();
  
    $('.c-discountcoupon__message').html('');
    $('.c-discountcoupon__message').removeClass('c-discountcoupon__message__error');
    $('.c-discountcoupon__message').hide();

    if(code == ''){

        $('.c-discountcoupon__message').html('Je cadeaubon/coupon code is niet geldig. Controleer je code en let op het verschil tussen 1 en hoofdletter i, 0 en O, Q en O.');
        $('.c-discountcoupon__message').addClass('c-discountcoupon__message__error');
        $('.c-discountcoupon__message').show();
        
    }else{

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/getdiscountcoupon/' + code,
            type: 'GET',
            data: { 
                    model: 'cart'
                   }, 
            success: function(result) { 
               $('.c-discountcoupon__message').html(result.message);
               if(result.status === 'ERROR'){
                    $('.c-discountcoupon__message').addClass('c-discountcoupon__message__error'); 
                    $('.c-discountcoupon__message').show();
               }else{ 
                    $('.c-discountcoupon__message').addClass('c-discountcoupon__message__approved');
                    $('.c-discountcoupon__message').show();
                    
                    //OPSLAAN van de code en pagina opnieuw laden
                    $.ajaxSetup({
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        }
                    });

                    $.ajax({
                        url: '/savediscountcoupontocart',
                        type: 'GET',
                        data: { 
                                id: result.id
                               }, 
                        success: function(result) { 
                            location.reload();
                        }
                    }); 
               }
            }
        });
    }
}


//
// REMOVE COUPON FROM CART
//
var removeDiscountcoupon  = function(){

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({
        url: '/removediscountcoupontocart',
        type: 'GET', 
        success: function(result) { 
            location.reload();
        }
    }); 
}
