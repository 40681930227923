$(function () {
    initProducts();
    initExtras();
    initProeflesCheckout();
    initCheckout();
});

function initProducts() {
    if ($('section#products').length > 0) {

        var urlParams = new URLSearchParams(window.location.search);

        if (urlParams.get('pakket') == 'start' || urlParams.get('pakket') == 'actie' || urlParams.get('pakket') == 'spoed') {
            $('.input-ttt').fadeIn('fast');
        } else {
            $('.input-ttt').fadeOut('fast');
        }

        if (urlParams.get('pakket') == 'start') {
            $('.input-praktijkexamen').fadeIn('fast');
        } else {
            $('.input-praktijkexamen').fadeOut('fast');
        }

        $('section#products .products .product').on('click', function () {
            $('section#products .products .product.active').find('.btn').text('Kies dit pakket');
            $('section#products .products .product.active').removeClass('active');
            $(this).addClass('active');
            $(this).find('.btn').text('Gekozen pakket');

            if ($(this).attr('id') == 'start' || $(this).attr('id') == 'actie' || $(this).attr('id') == 'spoed') {
                $('.input-ttt').fadeIn('fast');
            } else {
                $('.input-ttt').fadeOut('fast');
                $('.input-ttt').removeClass('active');
                $('.input-ttt').find('input[name="ttt"]').prop('checked', false);
            }

            if ($(this).attr('id') == 'start') {
                $('.input-praktijkexamen').fadeIn('fast');
            } else {
                $('.input-praktijkexamen').fadeOut('fast');
                $('.input-praktijkexamen').removeClass('active');
                $('.input-praktijkexamen').find('input[name="praktijkexamen"]').prop('checked', false);
            }

            refreshCheckout();
        });

        $('[type="checkbox"]').on('click', function () {
            refreshCheckout()
        });

        // const $variant = $('[name="variant"]');
        // const variant = $variant.val();
        // if (variant !== '') {
        //     $('section#products .products .product.active').removeClass('active');
        //     $('section#products .products .product.active').find('.btn').text('Kies dit pakket');
        // }

        const $theoriebox = $('[name="theoriebox"]');
        if ($theoriebox.is(':checked')) {
            $theoriebox.closest('label').addClass('active');
        }

        const $slipcursus = $('[name="slipcursus"]');
        if ($slipcursus.is(':checked')) {
            $slipcursus.closest('label').addClass('active');
        }

        const $ttt = $('[name="ttt"]');
        if ($ttt.is(':checked')) {
            $ttt.closest('label').addClass('active');
        }

        const $praktijkexamen = $('[name="praktijkexamen"]');
        if ($praktijkexamen.is(':checked')) {
            $praktijkexamen.closest('label').addClass('active');
        }

        if (history.pushState) {
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?pakket=' + $(this).attr('id');
            window.history.pushState({ path: newurl }, '', newurl);
        }

        refreshCheckout();
    }
}

function initExtras() {
    if ($('#direct_sales .extras .extra').length > 0) {
        $('#direct_sales .extras .extra').each(function () {
            $(this).find('input').change(function () {
                $(this).parents('.extra').toggleClass('active');
            });
        });
    }
}

function initProeflesCheckout() {
    const $proefles = $('#direct_sales form input[name="proefles"]');
    const $checked = $('#direct_sales form input[name="proefles"]:checked');
    if ($proefles.length > 0) {
        $proefles.change(function () {
            if ($(this).val() === 'Nee') {
                $('.geen-proefles').slideDown('fast');
                $('.wel-proefles').slideUp('fast');
            } else {
                $('.wel-proefles').slideDown('fast');
                $('.geen-proefles').slideUp('fast');
                $('#geen-proefles').prop('checked', false);
            }
        });

        $(document).ready(function () {
            if ($checked.val() === 'Nee') {
                $('.geen-proefles').slideDown('fast');
            }

            if ($checked.val() === 'Ja') {
                $('.wel-proefles').slideDown('fast');
            }
        });
    }
}

function refreshCheckout() {
    const $variant = $('[name="variant"]');
    const $active = $('section#products .products .product.active');
    if ($active.length > 0) {
        if (history.pushState) {
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?pakket=' + $active.attr('id');
            window.history.pushState({ path: newurl }, '', newurl);
        }

        if ($(window).width() < 768) {
            $('html, body').animate({
                scrollTop: $('.cart').offset().top - ($('header').outerHeight() + 15)
            }, 1000);
        }
        
        const variant = $active.data('variant');

        $variant.val(variant);

        const data = $('#directSalesForm').serialize();

        $.ajax({
            type: 'POST',
            url: url.directSales.data,
            data: data,
            dataType: 'json',
            success: function (data) {
                updateCart(data);
            }
        });
    }
}

function updateCart(data) {
    const $products = $('[data-field="products"]');
    const $discount = $('[data-field="discount"]');
    const $total = $('[data-field="total"]');
    $products.empty();

    const productTemplate = '<div class="row product">\n' +
        '<div class="col-xs-6 title">{{ name }}</div>\n' +
        '<div class="col-xs-6 value">{{ price }}</div>\n' +
        '</div>';

    data.items.forEach(function (item, i) {
        const product = productTemplate.replace(/{{ name }}/g, item.name)
            .replace(/{{ price }}/g, item.price);

        $products.append(product);
    });

    $discount.text('- ' + data.discount);
    $total.text(data.total);
}

function initCheckout() {
    if ($('#checkout').length > 0) {
        initCheckoutLoadSettings();
        $('.form-control').on('keyup', function () {
            initCheckoutSaveSettings();
        });

        checkPostalZip();
        jQuery('input[name="postal_code"], input[name="house_number"]').keyup(function () {
            checkPostalZip();
        });
    }
}

var checkPostalZip = function () {
    var zipcode = jQuery('input[name="postal_code"]').val();
    var housenumber = jQuery('input[name="house_number"]').val();

    if (zipcode !== '' && housenumber !== '') {
        delayGetAdress(function () {
            getAdressByPostalZip(zipcode, housenumber);
        }, 400);
    }
};

var getAdressByPostalZip = function (zipcode, housenumber) {
    zipcode = cleanUpZipcode(zipcode);

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $.ajax({
        url: '/shop/request/adresbyzipcode',
        type: 'POST',
        data: {
            zipcode: zipcode,
            housenumber: housenumber
        },
        async: true,
        success: function (result) {
            result = JSON.parse(result);
            if (typeof (result.exception) == 'undefined') {
                handleAdressRequestData(result);
            }
        }
    });
};

var delayGetAdress = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

var handleAdressRequestData = function (adress) {
    jQuery('input[name="street"]').val(adress.street);
    jQuery('input[name="city"]').val(adress.city);
};

var cleanUpZipcode = function (zipcode) {
    var newZipcode = zipcode.trim();
    newZipcode = newZipcode.replace(/ /g, '');
    newZipcode = newZipcode.toUpperCase();
    return newZipcode;
};

function initCheckoutLoadSettings() {
    $('#first_name').val(localStorage.first_name);
    $('#last_name').val(localStorage.last_name);
    $('#organization').val(localStorage.organization);
    $('#email').val(localStorage.email);
    $('#phone').val(localStorage.phone);
    $('#street').val(localStorage.street);
    $('#house_number_addition').val(localStorage.house_number_addition);
    $('#house_number').val(localStorage.house_number);
    $('#postal_code').val(localStorage.postal_code);
    $('#city').val(localStorage.city);
    $('#comment').val(localStorage.comment);
}

function initCheckoutSaveSettings() {
    localStorage.first_name = $('#first_name').val();
    localStorage.last_name = $('#last_name').val();
    localStorage.organization = $('#organization').val();
    localStorage.email = $('#email').val();
    localStorage.phone = $('#phone').val();
    localStorage.street = $('#street').val();
    localStorage.house_number_addition = $('#house_number_addition').val();
    localStorage.house_number = $('#house_number').val();
    localStorage.postal_code = $('#postal_code').val();
    localStorage.city = $('#city').val();
    localStorage.comment = $('#comment').val();
}